body {
  content: "";
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow-y: hidden;
}

#root {
  height: 100vh;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #f7d117ab;
}

.react-mde .grip {
  height: auto !important;
}

.d2h-code-side-linenumber {
  position: initial !important;
}

.d2h-code-linenumber {
  position: initial !important;
  width: 100% !important;
}

.d2h-ins {
  width: 100% !important;
}

.d2h-del {
  width: 100% !important;
}

.d2h-code-side-emptyplaceholder,
.d2h-emptyplaceholder {
  width: 100% !important;
}

.d2h-code-side-line {
  padding: 0 0 0 1rem !important;
}

.d2h-code-line-ctn {
  padding: 0 0 0 1rem !important;
}

.d2h-code-line {
  padding: 0 0 0 1rem !important;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.tox-tinymce {
  border-radius: 4px !important;
}

.d2h-wrapper {
  color: black !important;
  background-color: white !important;
  border-radius: 4px !important;
}

.configuration-selectors {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 24px;
}

#changelog a {
  color: #1976d2;
  font-weight: bold;
  font-size: 1.1em;
  text-decoration: none;
  opacity: 0.8;
  transition: color 50ms ease-out;
}

#changelog a:hover {
  border-bottom: 2px solid #1976d2;
  opacity: 1;
}

.dark-theme #changelog a {
  color: #face0a;
}

.dark-theme #changelog a:hover {
  border-bottom: 1px solid #face0a;
}

a {
  cursor: pointer;
}
